.tutorReport{
    padding-top: 40px;
    min-height: 100vh;
    
}
.tutorReport__content{
    /* padding-top: 20px; */
}
.tutorReport__content__table{
    padding: 0;
    margin: 0;
    /* padding-left: 12%; */

    
}
.createbtn{
    display: flex !important;
    /* justify-content: left !important; */
    position: fixed;
    top:63%;
    left: -150px;
    padding-left: 150px;
    

    
}
.tutorReport__content__table Table tbody tr td{
    /* display: grid !important; */
    /* grid-template-columns: 1fr 2fr 1fr; */
    
}
.tutorReport__content__table Table tbody tr {
    /* display: flex;
    width: 100% !important;
    flex-wrap: wrap; */
    /* flex-direction: row; */
    /* width: 100% !important; */
    /* flex-flow: wrap; */
    
}
td{
    /* display: inline-flex; */
}


.active-modal {
    overflow-y: hidden;
}

.btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
}

.modal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background: rgba(49,49,49,0.8);
}
.modal-content {
    /* position: absolute; */
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    /* background: #f1f1f1; */
    padding: 0 28px;
    /* border-radius: 3px; */
    max-width: 600px;
    min-width: 300px;

}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
}
.tutorReport__content__title{
    color: var(--secondary);
    font-size: 25px;
    text-align: center;
    font-weight: bold;
    padding: 10px;
    text-transform: capitalize;
    
}
.creater{
    position: sticky;
    top:60px;
    display: flex;
    background-color: var(--bodyback);
    justify-content: center;
  
}
.creater .init{
    padding: 10px 0 0 0  !important;
}
.submit_btn{
    background-color: var(--secondary);
    color: white;
}
.student_comment_input{
    width:100%;
    display: flex;

}
.meeting_report tr{
    border-bottom: 2px solid white;
}
@media only screen and (min-width:768px){
    .creater{
        position: sticky;
        top:80px;
        justify-content: left;
      
    }
    .student_comment{
        width:50%
    }
    .half{
        width:50%
    }
    .tutorReport__content__title{
        
        padding: 30px;
       
        
    }
    
}