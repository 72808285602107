a{
    padding-top: 1.5% !important;
}
.footer{
    background-color: var(--secondary);
    color: var(--white);
    text-align: center;
    padding-top: 3%;
    padding-bottom: 1%;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
}

.footer__icons{
    display: flex;
    justify-content: center;
    
}
.footer__icons a{
    padding: 10px;
    /* font-size: 4vw; */
    /* width: 50px; */
}

/* .footer__contact{
    padding-top: 5%;
    font-size: 4vw;
    
} */
.footer__contact__items p{
    display: flex;
    flex-direction: column;
    font-size: 15px;
}

.footer__links p{
    /* display: flex;
    flex-direction: column; */
    /* font-size: 3vw; */
}
.footer__links {
    display: flex;
    /* font-size: 2.5vw; */
    text-align: center;
    justify-content: center;
    /* padding-top: 0 !important; */
    
}

.footer__link{
    /* display: flex; */
    /* justify-content: center; */
    /* text-align: center; */
    /* width: 100px; */
    color: rgb(207, 206, 206) !important;
    font-size: 12px;
    
}
.footer__copyright{
    display: flex;
    justify-content: center;
    text-align: center;
    width: auto;
    color: rgb(207, 206, 206) !important;
    font-size: 12px;
    
}
@media screen and (min-width: 576px) {
    
    /* .footer__contact{
        padding-top: 1.5%;
        font-size: 3.5vw;
        
    } */
    .footer__contact__items p{
        display: flex;
        flex-direction: column;
        
    }
    .footer__contact__items{
       
        display: flex;
        justify-content: center;
        padding-bottom: 10px;
    }
    .footer__contact__items p{
        font-size: 13px;
        padding: 5px;
        border-bottom: solid 2px white;
    }



}
@media screen and (min-width: 768px) {
    .footer{
        
       margin-bottom: 0 !important;
       /* margin-top: calc(100vh - 500px); */
       
    }
    .footer__top{
        font-size: 30px;
    }
    .footer__icons a{
        padding-top: 2%;
        
    }
    /* .footer__contact{
        
        font-size: 2vw;
        
    } */
    .footer__contact__items p{
        font-size: 20px;
        padding: 8px;
        border-bottom: solid 2px white;
        
    }
    .footer__copy{
        display: flex;
        justify-content: center;
    }
   
    .footer__copyright{
        display: flex;
        justify-content: center;
        width: auto;
        text-align: center;
        color: rgb(207, 206, 206) !important;
        font-size: 1.2vw;
    }
    .footer__link{
        display: flex;
        justify-content: center;
        text-align: center;
        width: auto;
        color: rgb(207, 206, 206) !important;
        font-size: 1.2vw;
        padding: 0 15px;
    }
}

