.createtbody td input{
    width: 300px;
}

.submit{
    
    text-align: center;
    
}
.starter{
    background-color:green;
    color: white;
    border: white 5px solid;
}
.submit input{
    
    text-align: center;
    background-color: var(--secondary);
    color: white;
    border-radius: 10px;
    text-transform: uppercase;
    font-weight: bold;
    padding: 5px;
}
.cancel button{
    text-align: center;
    background-color: red;
    color: white;
    border-radius: 10px;
    text-transform: uppercase;
    font-weight: bold;
    padding: 5px;
    width: 100%;
}
.create-btn{
    border: none !important;
    background-color: green;
    padding-bottom: 5px;
    margin-bottom: 15px;
    font-weight: bold;
    border: 10px solid rgba(255,255,255,1);
}
.create-btn:hover {
    scale: 110%;
    background-color: green;
    transition: .2s ease-in-out;

}