.adminmenu__header{
    background-color: var(--secondary);
    padding: 10px;
    /* font-weight: bold; */
    position: fixed;
    width:100vw;
    
   
}
.adminmenu__header a{
    color:white !important;
}

.nav-item{
display: flex;
align-items: center;
}
.nav-link{
    
    display: flex;
align-items: center;

}
.dropdown-item a{
    color:var(--secondary) !important;
font-weight: bold;
}
