.resources_menu{
    background-color: var(--secondary);
    justify-content: center;
    position: fixed; 
}
.resources_menu_item{
    color:white !important;
    margin: 0 5px ;
    padding: 5px 10px;
    background-color: var(--secondary);
    text-transform: capitalize;
    
    
}
@media only screen and (min-width:768px){
    .resources_menu{
        position: static;
    }
   
.resources_menu_item{
    width:100%;
    margin: 0;
    padding: 20px 0;
    font-size: 1.3rem;
   
    border-bottom:3px solid white;
}
.resources_menu_item:hover{
   background-color: var(--primary);
    padding: 25px 0;
    
    border-bottom:2px solid white;
    transition: .2s ease-in-out;
}
.resources_menu_items{
    position: fixed;
    left:0;
    top:75px;
    width: 16.5%;
}

}