.templates{
    min-height: 100vh;
}
.single__template{
    border: 2px solid var(--secondary);
    border-radius: 5px;
}
.single__template__disc{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
    -webkit-box-orient: vertical;
}
.template__show{
    overflow: visible;
    display: block;
}
.single__template__link{
    border: 1px solid var(--secondary);
    border-radius: 5px;
    cursor: pointer;
}
.single__template__link:hover{
    background-color: var(--primary);
    color: white !important;
    border: none;
}
.template__read{
    cursor: pointer;
}
@media only screen and (min-width:992px){
.single__template__links{
    border-left: 1px solid var(--secondary);
}
.single__template__price{
    font-size: 1.5rem;
}
}