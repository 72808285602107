.new_trainee_wait{
    
    padding-left: 25vw;
    padding-top: 30px;
    justify-content: left;
    min-height:100vh;

}
.new_trainee_wait__content{
    /* background-color: rgb(195, 195, 225); */
    font-size: 14px;

}
.new_trainee_wait__read button{
    background-color: var(--secondary);
    color:white;
    padding:5px 10px;
    border-radius: 5px;
    text-transform: capitalize;
}
.new_trainee_wait_thankyou{
    font-weight: bold;
    font-size: 16px;
}
.new__terms__of__serviece{
    font-size: 14px;
}
.term_title{
    color: var(--secondary);
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
    padding: 0.5rem;
}
.contacts:hover{
    scale:110%;
    padding: 3px 10px !important;
    margin: 10px;
    
    border: 2px solid var(--primary);
    border-radius: 5px;
    transition: .2s ease-in-out;
}
@media only screen and (min-width:768px){
    .new_trainee_wait{
    
        padding-left: 10vw;
        
        
    
    }
  
    .new_trainee_wait__content{
        /* background-color: rgb(195, 195, 225); */
        font-size: 20px;
    
    }
    .new_trainee_wait__read button{
        background-color: var(--secondary);
        color:white;
        padding:5px 10px;
        border-radius: 5px;
        text-transform: capitalize;
    }
    .new_trainee_wait_thankyou{
        font-weight: bold;
        font-size: 30px;
    }
    .new__terms__of__serviece{
        font-size: 18px;
    }

}