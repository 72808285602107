.studyhead {
  width: 100%;
  /* padding-top: 10px; */
  border: 25px solid var(--secondary);
}
.studyhead__left {
  background-color: var(--white);
  color: var(--secondary);
  padding: 0 10px 0 10px;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 800;
  line-height: 30px;
  font-weight: bolder;
}
.studyhead__left__see{
  display: none !important;
}
.studyhead__right__see {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--secondary);
  padding: 10px 0;
  border-radius: 20px;
}
.studyhead__right {
  background-color: var(--secondary);
  color: var(--white);
  font-size: 1rem;
  
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 10px 0;
  
}
.studyhead__right__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  height: 120px;
  /* padding: 10% 0; */
  text-align: center;
  /* border-bottom: 3px solid var(--white); */
  padding: 20px 2px;
}
.studyhead__right__item div{
  line-height: 1.5rem;
  
}

.studyhead__right__item img {
  align-items: center;
  align-self: center;
  width: 50%;
}
@media screen and (min-width: 576px) {
  .studyhead__right {
    font-size: 1.5rem;
    padding: 10px;
    line-height: 1.5rem;
    
  }
  .studyhead__right__item img {
    width: 45%;
  }
  .studyhead__right__item {
    height: 200px;
    padding-bottom: 20px;
   
  }
  .studyhead__left {
    font-size: 2.5rem;
    line-height: 45px;
  }
  
}
@media screen and (min-width: 768px) {
  .studyhead {
    /* width: 100%; */
    display: grid;
    grid-template-columns: 1fr 3fr;
    border: none;
    
  }
  .studyhead__left {
    font-size: 3rem;
    line-height: 100%;
    border-left: 25px solid var(--secondary);
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    justify-content: space-around;
   
   
  }
  .studyhead__left__see{
    display: block !important;
    font-size: 1.5vw;
    font-weight: normal;
    font-style: italic;
    /* padding-top: 2vw; */
    line-height: 10px;
    /* color: var(--primary); */
    
  }

  .studyhead__right {
    background-color: var(--secondary);
    /* padding-top: none; */
    /* padding: 3vw 10px 3vw 10px; */
  }

  .studyhead__right__see {
    display: none;
  }
  .studyhead__right {
    font-size: 1.3rem;
  }
  .studyhead__right__item {
    align-self: center;
    /* height: 150px; */
    text-align: center;
    border-bottom: none;
    /* padding-bottom: 50%; */
  }
  .studyhead__right__item img {
    align-items: center;
    align-self: center;
    width: 40%;
  }
}
