.modalbox-body{
position: absolute;
width:85%;
top:30vh;
background-color: white;
border-radius: 10px;
}
.modal-content-centered{
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
.modal-content-text{
    text-transform: capitalize;
    color: var(--primary);
    font-weight: bold;
    padding: 20px;
    font-size: 1rem;
}
.modal-buttons{
    display: flex;
}
.modal-btn a{
    padding: 20px !important;
    color: white;
}
.modal-btn{
    background-color: var(--secondary);
    color: white !important;
    margin: 0 10px ;
    border: none;
    padding: 10px 20px !important;
    border-radius: 10px;
    font-weight: bold;
}
.modal-btn:hover{
    background-color: var(--primary);

}
@media only screen and (min-width:768px){
    .modal-content-text{
      
        padding: 25px;
        font-size: 1.5rem;
    }
    .modalbox-body{
        position: absolute;
        width:100%;
        top:30vh;
        background-color: white;
        border-radius: 10px;
        }
        .modal-btn{
           
            margin: 0 15px ;
           
            padding: 15px 25px !important;
        }
           
}