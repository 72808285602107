
.profile__btn {

    /* font-size: 25px; */
    background-color: white;
    
  }
  .profile_icon{
    width:80px;
    
  }
  
 
  .profile__name{
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .profile-content {
    font-size: 16px;
    display: none;
    position: absolute;
    right:0;
    top:150;
    align-self: center;
 
    background-color: #f1f1f1;
    min-width: 200px;
    padding:10px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .profile-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    
  }
  .profile-content__dashboard{
    padding: 10px 0 0 0;
  }
  .profile-content__dashboard:hover a{
    scale: 120%;
    transition:  .1s ease-in-out; 
  }
  
  .profile-content div:hover {
    background-color: #ddd;
    }
  .profile-content button:hover {background-color: #ddd;}
  
  /* .profile:hover .profile-content {display: block;} */
  
  .profile:hover .profile__btn {background-color: #ffffff;}
  .profile-content__icon{
    font-size: 60px;
  }
  .profile-content__user{
    padding-bottom: 20px;
  }
  .profile-content__signout{
    padding: 15px 0;
    /* background-color: none;; */
  }
  
  .profile-content__signout:hover button{
    
    background-color:#ddd;
    scale: 120%;
    transition:  .1s ease-in-out; 
  }
  .profile-content__name{
    font-size: 0.9rem;
  }