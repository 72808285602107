:root {
  --bodyback: #f2f2f2;
  --primary: #e35a01;
  --secondary: #003d76;
  --lighttext: #203946;
  --white:#ffffff;
}
body {
  background-color: var(--bodyback);
  max-width: 100%;
  object-fit: contain;
  
}

