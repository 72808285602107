p {
    margin-bottom: 0 !important;
  }
  a {
    color: var(--secondary) !important;
    text-decoration: none !important;
    text-align: center;
    line-height: 18px;
    display: flex;
    align-self: center;
    padding: 5px;
    font-size: 16px;
    
  }
  .header__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  /* .header__right {
    display: flex;
    justify-content: right;
  } */
  
  .header {
    background-color: var(--white);
    border-bottom: 6px solid var(--primary);
  }
  
  .header__right {
    color: var(--secondary);
  }
  .navbar-toggler {
    margin-left: auto;
    margin-right: 5%;
  }
  .header__left__a:nth-child(1) {
    font-family: "Lato", sans-serif;
    font-weight: 1700;
    font-weight: 900;
    font-size: 25px;
    padding-left: 20px;
    color: var(--primary);
    display: flex;
    align-items: center;
  }
  .header__left__s:nth-child(2) {
    font-family: "Lato", sans-serif;
    font-weight: 100;
    font-weight: bolder;
    font-size: 15px;
    padding-top: 7px;
    color: var(--primary);
    display: flex;
    align-items: center;
  }
  .header__right__navs a{
    padding: 10px;
  }
  .header__right__navs a:hover{
    scale:110%;
    padding-left: 20px;
    padding-right: 20px;
    transition: .1s ease-in-out;
  }
  button{
    color: var(--secondary);
    border: none;
    background-color: none;
    font-weight: 700;
      font-size: 16px;
      line-height: 5px;
  }
.profile{
  font-size: 40px;
}
  @media screen and (min-width: 576px) {
    .header__left__a:nth-child(1) {
      font-family: "Lato", sans-serif;
      font-weight: 1700;
      font-weight: 900;
      font-size: 38px;
      color: var(--primary);
    }
    .header__left__s:nth-child(2) {
      font-family: "Lato", sans-serif;
      font-weight: 100;
      font-weight: bolder;
      font-size: 20px;
      padding-top: 14px;
      color: var(--primary);
    }
    .navbar-expand-md .navbar-collapse{
      justify-content: right;
    }
  }
  @media screen and (min-width: 768px) {
    .header__left {
      /* margin-left: 20px; */
    }
    .header__right {
      font-weight: 700;
      font-size: 12px;
      line-height: 5px;
    }
    .navbar {
      --bs-navbar-padding-y: -0.5rem !important;
    }
    .navbar-nav {
      --bs-nav-link-a-color: var(--bs-nav-link-a-color);
    }
    .header__right__navs {
      display: flex;
      color: black;
      

    }
    .nav-link {
      display: flex;
      justify-content: right;
    }
  }
  