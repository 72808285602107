.video {
}
.videos__title {
  color: var(--secondary);
  font-weight: bold;
  text-align: center;
  padding: 20px 0;
  font-size: larger;
  
}
.single__video{
    margin-bottom: 30px;
}
.single__video iframe {
  width: 100%;
  height: 50vw;
  object-fit: contain;
}
.single__video__title {
  text-align: center;
  font-weight: bold;
  padding: 5px;
  text-transform: capitalize;
  border-bottom: 2px solid var(--secondary);
  display: flex;
  align-items: center;
  height:5rem;
}
@media only screen and (min-width: 576px) {
  .single__video iframe {
    height: 25vw;
  }
  .videos__title {
    font-size: 2.5rem;
    padding: 2rem;
  }
}
@media only screen and (min-width: 992px) {
    .single__video iframe {
        height: 14vw;
      }
      .videos__title {
        font-size: 3rem;
        padding: 5rem 0 3rem 0;
      }
}

