.confirm {
  padding-top: 80px !important;
  
}
.item_single {
  border-left: 5px solid black;
  border-right: 5px solid black;
  padding: 0 !important;
  margin: 0 !important;
}
.confirm_title {
  color: var(--secondary);
  font-size: xx-large;
  text-align: center;
  font-weight: bolder;
}
.confirmed_main{
  margin: 30px 0 0 0;
}
.confirmed_details {
  display: none;
  width: 100%;
   
  /* padding-bottom:30px; */
}
.confirmed_details:hover {
  width: 100%;
  height: 100%;
  opacity: 100%;
  display: block;
  border-bottom: 5px red solid;
  margin-bottom: 30px 0;

}

.confirmed_main:hover + .confirmed_details {
  display: block;
  height: 100%;
  opacity: 100%;
  margin-bottom: 30px;

  transform: translatex(0%);

  transition-timing-function: ease-in-out;

  transition: 0.5s;
}

table {
  padding: 20px;
  font-weight: bold;
  text-align: left;
  color: var(--secondary);
  border-bottom: var(--secondary) 5px solid;

  background-image: linear-gradient(rgb(255, 255, 255), var(--primary) 100%);
}
/* #725c5c */
/* rgb(132, 132, 132) */
.confirmed_details th {
  text-align: center;
  text-transform: uppercase;
  font-weight:bold;
  font-size: 25px;
}
.confirmed_details td {
  border: solid 2px rgb(246, 246, 246);
  padding: 5px;
  font-size: 18px;
  display: flex;
  align-items: center;
}
.confirmed_details tbody {
  display: grid;
  grid-template-columns: 1.3fr 3fr;
}
/* .last{
    height:500px;
    padding:250px;
} */
.confirm {
  margin-bottom: 500px;
}
.joinbtn {
  background-color: var(--secondary);
  color: white;
  width: 100% !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  padding: 10px;
  margin-bottom: 0;
  border-radius: 0px;
}
.joinbtn:hover{
background-color: var(--secondary);
font-size: 40px;
}
.joinbtn-mod{
  background-color: var(--secondary);
  color: white;
  width: 78vw !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  padding: 10px;
  margin-bottom: 0;
}
.confirm .project__detail{
  height:100%;

}

