textarea{
    width: 100%;
    
}
.meeting_report{
   /* padding-left: 12%; */
   white-space: pre-wrap;
}
.meeting_report tbody tr{
   display: grid; 
   grid-template-columns: 1fr 3fr 1fr 3fr;
}
.meeting_report thead tr{
   display: grid; 
   grid-template-columns: 1fr 3fr;
}
.meeting_report tfoot  td{
   display: flex; 
   justify-content: center;
   width: 100%;
   padding: 0 15px;
  
}
tfoot td button{

   width: 100%;
   padding: 0 15px;
}


.meeting_report .btn button{
background-color: var(--secondary);
color: white;
padding: 5px 15px;
}
.status{
   justify-content: space-between;
   text-align: right;
   background-color:red;
   color:white
}
.approved{
   background-color:green;
   color: black;
}
.declined{
background-color: red;
color: black;
}
.top{
   justify-content: space-between;

}
.join_meeting{
   background-color: var(--secondary);
   color:white !important;
   padding: 10px 20px;
   text-transform: capitalize ;
   font-weight: bold;
   border-radius: 10px;
   font-size: 1.2rem;
   
}
@media only screen and (min-width:576px){
   .tutor_daily_report tr{
      width: 100%;
   }
   .tutor_daily_report tr td:nth-child(1){
   width:10%
   }
   .tutor_daily_report tr td:nth-child(2){
   width:30%
   }
   .tutor_daily_report tr td:nth-child(3){
   width:10%
   }
   .tutor_daily_report tr td:nth-child(4){
   width:30%
   }
}