.group{
    padding-top: 50px;
}
.group__top__image img{
    width: 100%;
}
.group__top__text{
    text-align: justify;
    padding: 5%;
    font-size: 1rem;
}
.group__aim{
background-color: var(--white);
border: solid 2px var(--grey);
padding: 2%;
text-align: center;
}
.group__aim__title{
font-size: 7vw;
font-weight: bold;
}
.group__aim__detail__left p:nth-child(1), .group__aim__detail__right p:nth-child(1){
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    padding: 10px;

}
.group__aim__detail__left p:nth-child(2),  .group__aim__detail__right p:nth-child(2){
    font-size: 14px;
    display: flex;
    justify-content: center;
    

}
.group__aim__summary p{
    font-size: 18px;
  padding-top: 10px;
  font-style: italic;

  
}
.group__project{
    margin-bottom: 20px;
    margin-top: 20px;

}
.group__behost{
    margin-bottom: 20px;
    margin-top: 20px;
}
.group__previous{
    text-align: center;
    font-size: 3vw;
    font-weight: bold;
    padding: 10px;
}

.group__project .project__join button{
    background-color: var(--secondary);
    color:white;
    padding:20px ;
    font-size: 2rem;

}
.group__aim__summary a{

    margin:0 10px !important;  
}
.aim_link {
    background-color: var(--secondary) !important;
    color:white !important;
    padding: 10px !important;
    border-radius: 10px;
    font-weight: bold;
    
   
}

.group__top__text__button{
    background-color:var(--secondary);
    color:white !important;
    width:100%;
    display: flex;
    justify-content: center;
    font-size: 35px;
    border-radius: 10px;
}
.aim_link{
    display: flex;
    justify-content: center;
}
.imported a{
    padding: 0px !important;
    display: flex;
    justify-content: center;
    width: 100%;
}
.group__top__text{
    line-height: 20px;
}
.group__top__text ul li{
    padding: 5px 0;
}
.group__top__text__button{
    font-size: 20px;
    line-height: 30px;
    padding: 10px;

}
@media screen and (min-width: 576px) {
    .aim_link{
        display: block;
        justify-content: center;
    }
    .group__aim__summary__btn{
        display: flex;
    }

}

@media screen and (min-width: 768px){
.group__top{
   
   padding: 0 20px 30px 20px ;

   display:grid;
   grid-template-columns: 2fr 3fr;
   align-items: center;
    
}
.group__top__image{
    object-fit: cover;
    padding: 0px 10px 0 0 ;

    
}
.group__top__text{
    overflow: visible;
    padding: 30px 0 30px 0 ;
    font-size: 20px;
    line-height: 25px;
}
.group__aim{
    
    padding-top: 10px;
}
.group__aim__title{
    font-size: 3vw;
}
.group__aim__detail{
    display: grid;
    grid-template-columns: 2fr 2fr;
}
.group__aim__detail__left p:nth-child(1), .group__aim__detail__right p:nth-child(1){
    font-size:2.2vw;
    font-weight: bold;

}
.group__aim__detail__left p:nth-child(2),  .group__aim__detail__right p:nth-child(2){
    font-size: 1.5vw;

}

}