.resources{
    margin-top: 35px;
    
}
.resources__content{
    margin-top: 50px;
}
@media only screen and (min-width:768px){
    .resources{
        margin-top: 57px;
    } 
    .resources_menu{
        position: static;
    }
    .resources__content{
        margin-top: 0px;
    }
}