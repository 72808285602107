.user{
    /* padding: 60px 0; */
    margin-top: 35px;
    min-height: 90vh;
}
.user__header{
        background-color: var(--secondary);
        padding: 10px 0;

}
.user__header{
    position: fixed;
    width: 100%;

}


.adminmenu__header__navs {
    
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    background-color: var(--secondary);
    color: white;
}
.adminmenu__header__navs:hover{
    border-top: 2px white solid;
    border-bottom: 2px white solid;
    padding: 5px 20px !important;
    transition:  .1s ease-in-out; 
    background-color:var(--primary);
    
}
.user__bottom{
    /* position: sticky; */
    padding-top: 0px;
    /* padding-left: 15%; */
    /* position:relative; */
}
.user__header a{
    color:white !important;
    
}
.user__projects{
    /* padding-top: 80px; */
    /* margin-top: 60px; */
    top: 0px;
    
}

.user__projects__content, .user__hosting__content{
    padding: 20px 0;
}
.user__projects__head, .user__hosting__head{
    color: var(--secondary);
    font-size: 25px;
    text-align: center;
    font-weight: bold;
    padding: 60px 0 0 0;
    /* padding-top: 100px; */

}
.zoom__link button{
    padding: 0 5px;
    margin:5px 10px;
}
.zoom__link{
    flex-direction: column;
}
.zoom__btn{
    background-color: var(--secondary);
    color: white !important;
    font-style: italic;
    padding:  5px;
    margin: 0 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
}
.zoom__btn:active{
background-color: red;
zoom: 120%;
}
.zoom__btn:hover{
/* background-color: red; */
scale: 120%;
transition: .2s ease-in-out;
}
.zoom__body{
    padding: 5px;
    display: flex;
    align-items: center;
}
.user .navbar-expand-md .navbar-collapse{
    justify-content: left !important;
}
.user__projects__content td, .user__hosting__content td{
    padding: 5px 0 10px 10px;
    border-bottom: 2px solid var(--primary);
}

@media screen and (min-width: 768px) {
    .user{
       
        margin-top: 57px;
    }

    .user__header{
        padding:  0px;
      
    } 
    .user__head{
       background-color: var(--secondary);
       min-height: 100vh;
    } 
    .zoom__link{
        flex-direction: row;
    }
    .user__header{
        position: fixed;
        width:16.66%;
    
    }
    .adminmenu__header__navs{
        width:100%;
        text-align: center;
        display: flex;
        justify-content: center;
        padding: 20px 50px !important;
        font-size: 1.2rem;
    }
    .adminmenu__header__navs:hover{
       
        padding: 30px 0 !important;
       
    }
    .user__projects__head, .user__hosting__head{  
        padding: 30px 0 0 0;
    
    }
    
   
}