.behost{
    border: 5px solid var(--primary);
    text-align: center;
    color: var(--secondary);
    background-color: white;
    margin-bottom: 10px;
    padding-top: 20px;border-radius: 10px;
    padding: 10px;
}
.behost:hover{
    scale: 105%;
    transition: .2s ease-in-out;
}
.behosttop__left__head, .behosttop__right__head{
    font-size: 1.5rem;
    font-weight: bold;
    padding: 10px;
}


.behost__bottom{
    font-size: 1.3rem;
    font-style: italic;
    color: var(--primary);
    padding: 15px;
}
.behosttop__middle__head{
    font-size: 3rem;
    font-weight: bold;
    padding: 1rem;
    
    
}

@media screen and (min-width: 576px) {
    .behost{
        /* line-height: 35px; */
        padding: 0px 10px 0 10px;
    }
}
@media screen and (min-width: 768px) {
    .behost{
        line-height: normal;
        padding: 10px 10px 0 10px;
    }
    .behosttop{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px  ;
    }

.behosttop__middle__head{
    font-size: 3rem;
    font-weight: bold;
    color: var(--primary);
    
}

    .behosttop__left__head, .behosttop__right__head{
        padding: 10px;
        font-size: 2.5vw;
        font-weight: bold;
    }
    .behosttop__middle__head{
        font-size: 5.8vw;
        font-weight: bold;
        color: var(--primary);
        line-height: 4vw;
        padding-bottom: 2vh;
    }
   
    .behosttop__middle{
        border-left: solid  ;
        border-right: solid  ;
        display: flex;
        flex-direction: column;
        align-self: center;
    }
    .behost__bottom{
        
        padding: 10px 0 10px 0;
        
    }
}