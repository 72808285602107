.qandasingle{
border: 15px solid;
    border-image: linear-gradient(to bottom, #bab9b9 0%, #f8f8f8 100%) 0 0 100% 0;
    
}
.qanda{
min-height: 100vh;
}
.single__question__question{
    font-size: 1.3rem;
    font-weight: bold;
}
.single__question__answer{
    white-space: pre-wrap;
        
    
}
.show{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
           
}
.answer__link{
    background-color: var(--primary);
    color:white !important;
    font-weight: bold;
    padding: 5px 15px !important;
    border-radius: 10px;

}