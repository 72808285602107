.documents{
    margin-top: 20px;
    min-height: 100vh;
}
.single_document{
    border: 2px solid var(--primary);
}
.single_document_detail{
    padding: 5px 10px;
}
.single_document_detail__title{
    color: var(--secondary);
    font-weight: bold;
    font-size: 1.5rem;
}
.single_document_link{
    background-color: var(--primary);
    color:white !important;
    padding: 5px 15px !important;
    border-radius: 5px;
    margin: 15px 15px 0 0  ;
}
.single_document_thumbnail_image{
    max-height: 50vh;
    object-fit: cover;
    object-position: top;
    
}
.single_document_link:hover{
padding: 10px 20px;
}
@media only screen and (min-width:768px){
    .documents{
        margin-top: 30px;
    }
    .single_document_detail__title{
        font-size: 2.5rem;
    }
    .single_document_link{
        background-color: var(--primary);
        color:white !important;
        padding: 15px 25px !important;
        border-radius: 5px;
        font-size: 1.3rem;
        margin: 15px 15px 0 0  ;
    }
    .single_document_thumbnail_image{
        max-height: 45vh;
        object-fit: cover;
        padding: 0 30px 0 0;
        object-position: center;
        align-self: center;
    }
    .single_document_link:hover{
        padding: 15px 50px !important;
        transition: .3s ease-in-out;
        }
}