.hostingform{
    margin-top: 60px;
    padding: 0 30px ;
}
.hostingform__head{
    text-align: center;
    font-weight: bolder;
    font-size: 1.5rem;
    color:var(--secondary)
}
.hostingform form div{
    /* display: flex; */
    width: 100%;
}
.hostingform form div p{
    color: var(--secondary);
    font-weight: bold;
    padding: 15px 10px 5px 0 ;
    
    
}
.hostingform form .full input{
    width: 100%;
}
.hostingform form .full textarea{
    width: 100%;
}
.hostingform form .items input{
    min-width: 120px !important;
   min-height: 1.3rem;
    
}
.note{
    color: black;
    font-weight: normal;
}
.apply{
    background-color: var(--secondary);
    color: white;
    font-weight: bold;
    width: 100%;
    margin: 20px 0;
}
input[type="date"]{
    /* background-color:var(--primary); */
    /* color: white; */
    background-image: url(../../../resources/images/calendaricon2.jpg);
    background-size:contain;
    background-repeat: no-repeat;
    background-position: right;
    padding-left: 5px;
    width:20px !important;
    width:min-content !important;
    
      }
      
      ::-webkit-calendar-picker-indicator{
        /* background-color: #ffffff url("../../../resources/images/calendar.png"); */
        background-image: url("../../../resources/images/blank.png");
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
      }
      input[type="time"]{
        /* background-color:var(--primary); */
        /* color: white; */
        /* padding: 5px; */
        background-image: url(../../../resources/images/clock.jpg);
        
        background-size:contain;
        background-repeat: no-repeat;
        background-position: right;
        width:20px !important;
        width:min-content !important;
        height:25px;
      }
      .timezoneConvert{
        display: flex;
        align-items: center;
        padding-top:2.5px !important ;
        color:var(--primary) !important;
        font-style: italic;
        cursor: pointer;
        
      }
    input[type="file"]{
        height:200px;
    }




    /* ===================== */
    #drop-zone{
      height:200px !important;
      color:red
    }
    .drop-zone {
        max-width: 200px;
        height: 200px;
        padding: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: "Quicksand", sans-serif;
        font-weight: 500;
        font-size: 20px;
        cursor: pointer;
        color: #cccccc;
        border: 4px dashed #009578 !important;
        border-radius: 10px;
      }
      
      .drop-zone--over {
        border-style: solid;
      }
      
      .drop-zone__input {
        display: none;
      }
      
      .drop-zone__thumb {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        overflow: hidden;
        background-color: #cccccc;
        background-size: cover;
        position: relative;
      }
      
      .drop-zone__thumb::after {
        content: attr(data-label);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 5px 0;
        color: #ffffff;
        background: rgba(0, 0, 0, 0.75);
        font-size: 14px;
        text-align: center;
      }
@media screen and (min-width: 576px) {}
@media screen and (min-width: 768px) {
  .hostingform__head{
    
    font-size: 2.5rem;
    
}
}