.personalform{
    margin-top: 75px;
    padding: 0 25px;
}
.personalform__head{
    text-align: center;
    font-weight: bolder;
    font-size: 5vw;
    color: var(--secondary);

}
.personalform form div{
    /* display: flex; */
    /* width: 100%; */
}
.personalform form div p{
    color: var(--secondary);
    font-weight: bold;
    padding: 15px 10px 5px 0 ;
    
    
}
.personalform form .full input{
    width: 100%;
}
.personalform form .full textarea{
    width: 100%;
}
.personalform form .items input{
    width: 25%;
    
}
.note{
    color: black;
    font-weight: normal;
}
.apply{
    background-color: var(--secondary);
    color: white;
    font-weight: bold;
    width: 100%;
    margin: 20px 0;
}
.sign__right__phone__para{
    display: flex;
    width: fit-content
}
.selecta{
    width:80px
}
.phone_code{
    width:60px
}
.phone_num{
    width:150px !important;
}
.time{
    width:150px
}
@media screen and (min-width: 576px) {
    .phone_code{
        width: 100% !important;
    }
    .personalform__head{
        font-size: 35px;

    }
}
@media screen and (min-width: 768px) {
    .items .one{
        display: inline-flex;
    }
}