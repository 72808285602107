/* a{text-decoration: none;} */
.project {
  /* padding: 5px; */
  padding: 0;
  margin-bottom: 10px 0;
  border: 10px solid;
  
  border-image: linear-gradient(to bottom, #bab9b9 0%, #f8f8f8 100%) 0 0 100% 0;
}
.project__counter {
  text-align: center;
  background-color: #d7d7d7;
  color: var(--primary);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  
}
.project__counter p {
  padding-top: 20px;
  text-align: center;
  display: inline-block;
  font-size: 30px;
  font-weight: bolder;
  line-height: 8vh;
  padding: 10px 0;
}
.project__counter p p {
  font-size: 4rem;
  line-height: 8vh;
}
.project__detail {
  background-color: var(--primary);
  color: var(--white);
  padding: 10px 10px 10px 10px;
  text-transform: capitalize;
}
.project__detail p {
  text-align: center;
  display: inline-block;
  display: flex;
  justify-content: center;
  font-size: 1rem;
  font-weight: bold;
}

.project__join {
  background-color: var(--secondary);
  color: var(--white);
  text-align: center;
  font-size: 2rem;
  font-weight: bolder;
 
  
 

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
}
.project__counter__see {
  display: none !important;
}
.project__join button{
    background-color: var(--secondary);
    color:white;
    /* padding:10px ; */

}

@media screen and (min-width: 576px) {
  .project__detail {
    padding-top: 25px;
  }
  
 
  /* .project__detail p {
    font-size: 2.5vw;
  } */
  

}
@media screen and (min-width: 768px) {
  .project {
    display: grid;
    grid-template-columns: 1fr 3fr;
    

  }
  .project__counter__parent{
    height: 100%;

  }
  .project__counter {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    background-color: #d7d7d7;
    padding: 20px 0;
    height: 100%;
    border-top-right-radius: 0px;
  }
  .project__counter p {
    font-size: 2.5rem;
    line-height: 3.5vw;
    padding: 10px;
    font-style: italic;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .project__counter p p{
    font-size: 6rem;
    line-height: 3.5vw;
    padding: 2rem;
   
  }
  .project__counter__see {
    padding-top: 10px;
    display: block !important;
    font-size: 1.5vw !important;
    font-weight: normal !important;
  }
  .project__detail p {
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 2vw;
    text-align: left;
    display: flex;
  }
  .project__detail  {
    border-top-right-radius: 20px;
    /* height:80%; */
    display:flex;
    flex-direction: column;
    justify-content: center;

  }
  .project__join {
    /* padding: 0%; */
    font-size: 3vw;
  }
}
