.trainer_section .title{
    color: var(--secondary);
    font-weight: bold;
    /* padding: 0 !important; */
    /* padding:0  0 0 10px !important; */
    margin:0 !important;
    /* padding: 15px 10px 5px 0; */
}
.title{
    
    padding:0!important;
    width:150px !important;
}
.selected_trainer{
    color: var(--secondary);
    font-weight: bold;
    text-transform: capitalize;
}
.view_profile a{
    background-color:var(--secondary);
    color:white !important;
    padding: 5px 15px !important;
    border-radius: 5px;
    margin-left: 20px;
}
.dayone,.daytwo{
    text-transform: uppercase;
    margin-right: 20px;
    font-weight: bold;
    color: var(--primary);
}
.times{
    border-radius: 5px;
}
.times:hover{
    border:2px solid var(--primary)
}









.red{
    background-color:var(--primary);
    color: white;
}
.red2{
    background-color:var(--primary);
    color: white;
}