.preloader{
 position: fixed;
   top:0;
   display: flex;
   flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(246, 246, 246, 0.9);
  z-index:6000;
  width:100%;
  height:100vh;
  
}

@-webkit-keyframes sk-bouncedelay {
  /* 0%, 80%, 100% { -webkit-transform: scale(0) } */
  /* 40% { -webkit-transform: scale(1.0) } */
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}



.parent div{
  width: 25px;
  height: 25px;
  background-color: rgb(20, 1, 51);
  /* margin: 0 5px; */
border-radius: 50%;
  display: inline-block;
  /* -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both; */
  animation: sk-bouncedelay 1.28s infinite ease-in-out both;
}
.parent{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap:10px
}

.parent .bounce1{
  -webkit-animation-delay: -1.28s;
  animation-delay: -1.28s;
}
.parent .bounce2{
  -webkit-animation-delay: -1.12s;
  animation-delay: -1.12s;
}

.parent .bounce3{
  -webkit-animation-delay: -0.96s;
  animation-delay: -0.96s;
}
.parent .bounce4{
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.parent .bounce5{
  -webkit-animation-delay: -0.64s;
  animation-delay: -0.64s;
}
.parent  .bounce6 {
  -webkit-animation-delay: -0.48s;
  animation-delay: -0.48s;
}
.parent .bounce7 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.parent .bounce7 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.parent .bounce9{
  -webkit-animation-delay: 5s !important;
  animation-delay: 5s !important;
  animation: sk-bouncedelay none;
  background-color: #fff;
}
.loader-logo{
  width:25px;
  height:25px;
  display: flex;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  border:none
}