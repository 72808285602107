body {
  background-color: var(--bodyback);
}
.home__banner__head {
  /* margin-top: 10px; */
  /* position: fixed; */
}

.home__banner {
  width: 100%;
  height: 200px;
  background-size: 100%;
  background-image: url("../../resources/images/banner_60.jpg");
  opacity: 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home__banner__text {
  
  padding-top: 60px;
  text-align: center;
  font-size: 5vw;
  font-weight: 900;
  color: var(--secondary);
  text-shadow: 50px;
  /* background-color: #fff;
  opacity: 70%; */
}
/* .heading {
  background-color: #fff;
  opacity: 70%;
} */
.Home__group {
  padding: 0 3% 0 3%;
  
}
.Home__group__parent a{
  line-height: 50px !important;
}
.Home__group__left__text {
  color: var(--primary);
  /* font-size: 260%;
    font-size: 45px; */
  text-align: center;
  font-weight: bolder;
}
.Home__group__left__text p {
  font-size: 35px;
  font-weight: bold;
}
.text-start{
  text-transform: capitalize !important;
}
.Home__group__left {
  background-color: var(--white);
 
}
.Home__group__right__see {
  color: var(--white);
  text-align: right;
  padding-right: 20px;
  line-height: 30px;
}
.Home__group__right {
  display: block;
  background-color: var(--primary);
  padding-top: 10px;
 

}
.Home__group__right__item p {
  font-size: 110%;
  margin-left: 2%;
  color: var(--white);
  line-height: 35px;
  text-align: center;
  
}
.Home__group__parent:hover{
  scale:105%;
  transition: .2s ease-in-out;
}
.home__study:hover{
  scale:105%;
  transition: .2s ease-in-out;
}


@media screen and (min-width: 576px) {
  .home__banner {
    width: 100%;
    height: 400px;
    background-image: url("../../resources/images/banner_60.jpg");
  }
  .home__banner p {
    font-size: 120%;
    color: var(--secondary);
    opacity: 100%;
  }
  .heading {
    padding-top: 12%;
    background: none;
  }
  .Home__group__parent a{
    line-height: 70px !important;
  }
  .Home__group__left__text {
    text-align: center;
    width: 100%;
  }
  .Home__group__left__text p {
    font-weight: bolder;
    font-size: 8vw;
    text-align: center;
  }

  .Home__group__right__item p {
    font-size: 6vw;
    font-weight: bold;
    line-height: 60px ;
  }
  .Home__group .behosttop__middle__head{
    font-size: 5vw;
  }
}
@media screen and (min-width: 768px) {
  .home__banner {
    height: 500px;
    background-image: url("../../resources/images/banner_60.jpg");
    background-position: bottom;
    /* background-attachment: fixed; */
  }
  .home__banner p {
    font-size: 50px;
    color: var(--secondary);
    opacity: 100%;
  }
  .heading {
    margin-top: -50px;
  }
  .Home__group {
    display: flex;
    width: 100%;
    margin-top: -8%;
    padding: 0 15%;
    
  }
  .Home__group__parent a{
    line-height: 40px !important;
  }
  .Home__group__left {
    width: 25%;
    font-weight: 900px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .Home__group__right {
    width: 75%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .Home__group__left__text {
    font-size: 3vw;
    font-weight: bold;
    padding: 10px;
    display: flex;
    align-items: center;
    line-height: 110%;
  }
  .Home__group__left__text p {
    font-size: 2.5vw;
  }
  .Home__group__right__item p {
    font-size: 2.5vw;
    font-weight: bold;
    vertical-align: middle;
    line-height: 50px;
  }
  .Home__group__right {
    vertical-align: middle;
    padding-top: 5%;
  }
  .Home__group__right__see {
    color: var(--white);
    line-height: 50px;
    /* padding-left: 100%; */
  }

  /* .home__head {
    display: block;
    margin-top: 20px;
    position: fixed;
    border: none;
  }
  .home {
    width: 100%;
  }
  .home__banner {
    width: 100%;
    background-position: bottom;

    height: 500px;
    background-image: url("../../resources/images/banner_image.jpg");
    opacity: 75%;
    /* margin-left: auto;
        margin-right: auto; */
  /* }
  .home__banner p {
    text-align: center;
    font-size: 80px;
  }  */
}
