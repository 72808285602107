.personal{
    padding-top: 75px;
}
/* .personal__study{
    padding-bottom: 20px;
}
.personal__banner{
    background :linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url(../../resources/images/study_banner.jpg);
    background-size: cover;
    background-position: center;
    height: 250px;
   

}
.personal__banner__text{
    font-size: 4vw;
    font-weight: bold;
    padding: 5vh;
    text-align: center;
    color: white;
}
.personal__banner__button{
   
    display: flex;
    justify-content: center;
    font-weight: bold;
} */
.personal__note{
    font-size: 0.9rem;
    font-style: italic;
    text-align: center;
    padding: 2vw;
    margin: 2vw 0;
    border-bottom:  15px solid ;
    border-image: 
    linear-gradient(
      to bottom , #bab9b9 0%, #f8f8f8 100%
    ) 0 0 100% 0;
}
.personal__body__right__image img{
    width: 100%;
    padding: 40px 0 0px 0;
}
.personal__body__left, .personal__body__bottom__cont{
    padding: 20px;
    border: solid 2px var(--secondary);
    border-radius: 10px;
}
.personal__body__left__head, .personal__body__bottom__head{
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
    /* padding: 2vw 0 2vw 0 ; */
}
.personal__body__left__text, .personal__body__bottom__text{
    font-size: 0.9rem;
    text-align: justify;
}
.apply{
    /* background-color: var(--secondary) !important; */
    color: white;
    font-weight: bold;
    width: 100%!important;
    line-height: 45px;
    margin-bottom: 20px;
    padding: 0 20px!important;
    border-radius: 10px;
    font-size: 1.5rem;
    
}
@media screen and (min-width: 576px) {}
@media screen and (min-width: 768px) {
    .personal__note{
        font-size: 1.5vw;
        background-color: white;
        margin-bottom: 0px;
        /* padding: 2vw; */
    }
    .personal__body{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 4vw;
        padding: 4vw;
        /* background-color: white; */
    }
    .personal__body__left__head, .personal__body__bottom__head{
        font-size: 2vw;
        padding: 0px !important;
    }
    .personal__body__left, .personal__body__bottom__cont {
        border: none;
        padding: 0px;
    }
    .personal__body__bottom__cont {
        padding:0 50px;
    }
    .personal__body__right__image img{
        width: 100%;
        padding: 20px 0 0px 0px;
        /* height: 520px; */
        /* height: 25vw; */
    }
    .personal__body__left__text{
        padding-top: 20px;
    }
    .personal__body__left__text, .personal__body__bottom__text{
        font-size: 1.2vw;
    }
    .apply{
        font-size: 2rem;
        border-radius: 10px;
    }
}