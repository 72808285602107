.app{
    height: 100vh;
}
.app_footer{
    margin-top: 500px !important;
    
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Russo+One&family=Titillium+Web:wght@300;400;700&display=swap');

.header, .user__header{
    font-family: 'Titillium Web', sans-serif;
    letter-spacing: 1px !important;
    font-weight: bold !important;
    font-size: 35px !important;
}
*{
    font-family: 'Titillium Web', sans-serif;  
}

