.usefull{
    min-height: 100vh;
    margin-top:10px;
    padding: 10px 40px;
}
.usefull_search{
    border:2px solid orange; 
    padding: 5px 10px;
    border-radius: 10px;
}
.single_link_thumb{
    height:auto;
    align-self: center;
   
}
.single_link_url{
    padding: 0 !important;
}
.single_link{
    /* border-bottom :2px solid red; */
    margin: 20px 0;
    border: 10px solid;
    border-image: linear-gradient(to bottom, #bab9b9 0%, #f8f8f8 100%) 0 0 100% 0;
}
.single_link_title{
    font-size: 1.2rem;
}
.single_link_btn{
    background-color: var(--secondary);
    width: 100% ;
    color: white;
    padding: 10px 0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    font-size: 1.3rem;
}
.single_link_btn:hover{
    /* border-bottom :2px solid red; */
    
    padding: 15px ;

    
}
.single_link_btn:hover .single_url{
    /* border-bottom :2px solid red; */
    font-size: 2rem;
    padding: 0 20px ;

    
}
@media only screen and (min-width:768px){
    .single_link_title{
        font-size: 1.5rem;
    }
}